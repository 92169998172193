import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "homework-setup"
    }}>{`Homework setup`}</h1>
    <p>{`There's a couple things I'd like you to set up before the workshop. Experience has shown setting this up during the workshop distracts from the exercises.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a Netlify account`}</strong>{` go to `}<a parentName="p" {...{
            "href": "https://netlify.com"
          }}>{`netlify.com`}</a>{` and follow their signup instructions. You shouldn't need to pay.`}</p>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Install netlify cli`}</strong>{` Netlify's CLI tool is great. Install it with `}<inlineCode parentName="li">{`npm i -g netlify-cli`}</inlineCode>{` or `}<inlineCode parentName="li">{`yarn global add netlify-cli`}</inlineCode></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create a Vercel account`}</strong>{` go to `}<a parentName="p" {...{
            "href": "https://vercel.com"
          }}>{`vercel.com`}</a>{` and follow their signup instructions. You shouldn't need to pay.`}</p>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Install vercel cli`}</strong>{` Vercel has a fantastic CLI tool. Install it with `}<inlineCode parentName="li">{`npm i -g vercel`}</inlineCode>{` or `}<inlineCode parentName="li">{`yarn global add vercel`}</inlineCode></li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create an AWS account`}</strong>{` go to `}<a parentName="p" {...{
            "href": "https://aws.amazon.com"
          }}>{`aws.amazon.com`}</a>{` and create an account. They ask for a credit card and we'll keep everything within the free tier.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Configure Serverless Framework`}</strong>{` we'll use the opensource serverless framework to play with AWS Lambda. It's a great alternative to AWS's CloudFormation templates, I'll explain why.`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Create an Admin IAM user`}</strong></p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Login to your AWS account and go to the `}<strong parentName="p">{`Identity & Access Management (IAM) page`}</strong>{`.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Click on Users and then Add user`}</strong>{`. Enter a name in the first field to remind you this User is related to the Serverless Framework, like `}<inlineCode parentName="p">{`serverless-admin`}</inlineCode>{`. `}<em parentName="p">{`Enable Programmatic access`}</em>{` by clicking the checkbox. Click Next to go through to the Permissions page. Click on `}<em parentName="p">{`Attach existing policies directly`}</em>{`. Search for and select `}<strong parentName="p">{`AdministratorAccess`}</strong>{` then click Next: Review. Check to make sure everything looks good and click `}<em parentName="p">{`Create user`}</em>{`.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`View and `}<strong parentName="p">{`copy the API Key & Secret to a temporary place`}</strong>{`. You'll need it in the next step.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Install serverless cli`}</strong>{` with `}<inlineCode parentName="p">{`npm i -g serverless`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn global add serverless`}</inlineCode></p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Configure serverless cli`}</strong>{` run this command with your keys from previous step`}</p>
          </li>
        </ol>
        <pre parentName="li"><code parentName="pre" {...{}}>{`serverless config credentials --provider aws --key YOUR_KEY --secret YOUR_SECRET
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "other-housekeeping"
    }}>{`Other housekeeping`}</h2>
    <p>{`After you've created your accounts and configured the CLI tools, make sure your computer can do the following:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`JavaScript development.`}</strong>{` Make sure you can run `}<inlineCode parentName="p">{`create-react-app something`}</inlineCode>{`. If that works, your computer has all pre-requisites installed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Git.`}</strong>{` Just in case you don't have it yet, make sure your computer works with git. We've had folks switch from macos to windows just before the workshop before and then nothing worked. No fun.`}</p>
      </li>
    </ol>
    <p>{`I'm going to be using a Mac and giving instrutions using the terminal. As long as you can follow NPM install and Git instructions, stick with the tools you're used to.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      